export const canvasFilledPercentage = (canvas: HTMLCanvasElement): number => {
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }

    const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
    const filled = data.reduce((result, piece) => {
        if (piece > 50) {
            return ++result;
        }

        return result;
    }, 0);

    return filled / (canvas.width * canvas.height) * 100;
}
