import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {PlayerResult} from "../../model/PlayerResult";
import {readLocalStorage, writeLocalStorage} from "../../utils/localStorage";
import {PlayField} from "./PlayField/PlayField";
import './PlayGame.css';

export type PlayGameProps = {
    players: string[];
    resetPlayers: () => void;
}

const RESULTS_STORAGE_KEY = 'drawGame-results';

export const PlayGame: FC<PlayGameProps> = ({players, resetPlayers}) => {
    const [playersToGo, setPlayersToGo] = useState<string[]>([]);
    const [results, setResults] = useState<PlayerResult[]>([]);

    useEffect(() => {
        const storedResults = readLocalStorage<PlayerResult[]>(RESULTS_STORAGE_KEY);
        if (storedResults) {
            setPlayersToGo([...players.slice(storedResults.length)]);
            setResults(storedResults);
        } else {
            setPlayersToGo(players);
        }
    }, [players]);

    const onResult = useCallback((result: PlayerResult) => {
        const newResults = [...results, result];
        setResults(newResults);
        writeLocalStorage<PlayerResult[]>(RESULTS_STORAGE_KEY, newResults);

        setPlayersToGo([...playersToGo.slice(1)]);
    }, [playersToGo, results]);

    const playAgain = useCallback(() => {
        setPlayersToGo(players);
        setResults([]);
    }, [players]);

    const currentLoser = results.reduce<PlayerResult|null>((loser, player) => {
        if (!loser || player.result < loser.result) {
            return player;
        }
        return loser;
    }, null);

    if (playersToGo.length === 0) {
        return <div className="PlayGame__loser">
            {currentLoser && <Fragment>
                <h2>Prohrál {currentLoser.player}!</h2>
                <p>Skóre: <strong>{currentLoser.result.toFixed(1)}</strong></p>
            </Fragment>}
            <button onClick={playAgain}>Hrát znovu</button>
        </div>
    }

    const currentPlayer = playersToGo[0];

    return <div className="PlayGame">
        <h2>Hraje {currentPlayer}:</h2>
        <p>Pokresli za 5 sekund co největší plochu.</p>
        <PlayField player={currentPlayer} onResult={onResult}/>
        <button onClick={resetPlayers} className="secondary">Změnit tým</button>
        {results.length > 0 && <div className="PlayGame__results">
            <h2>Výsledky:</h2>
            <ul>
                {results.map((result) => (
                    <li key={result.player} className={currentLoser && currentLoser.player === result.player ? 'loser' : undefined}>
                        {result.player}: <strong>{result.result.toFixed(1)}</strong>
                    </li>
                ))}
            </ul>
        </div>}
    </div>

}
