import {FC, FormEvent, Fragment, useCallback, useEffect, useRef, useState} from "react";
import './PlayerNamesForm.css';

export type PlayerNamesFormProps = {
    playersCount: number;
    storePlayers: (players: string[]) => void;
    resetCount: () => void;
}

export const PlayerNamesForm: FC<PlayerNamesFormProps> = ({playersCount, storePlayers, resetCount}) => {
    const [error, setError] = useState<string|null>(null);

    const firstInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [playersCount]);

    const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const players = (new FormData(event.target as HTMLFormElement)).getAll('players') as string[];
        if (!players.some((player, index) => {
            if (player.trim() === '') {
                setError(`Vyplňte jméno hráče ${index + 1}`);
                return true;
            }
            return false;
        })) {
            storePlayers(players);
        }
    }, [storePlayers]);

    return <div className="PlayerNamesForm">
        <h2>Zadejte jména hráčů</h2>
        {error && <div className="PlayerNamesForm__error">{error}</div>}
        <form onSubmit={onSubmit}>
            {Array.from({length: playersCount}).map((_, index) => {
                const id = `player${index}`;
                return <Fragment key={index}>
                    <label htmlFor={id}>{index + 1}. hráč</label>
                    <input ref={index === 0 ? firstInputRef : undefined} type="text" id={id} name="players"/>
                </Fragment>
            })}
            <div>
                <button type="submit">Jdeme hrát</button>
            </div>
        </form>
        <div>
            <button onClick={resetCount} className="secondary">Změnit počet hráčů</button>
        </div>
    </div>
}
