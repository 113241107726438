import {FC, MouseEventHandler, useCallback, useEffect, useRef, useState} from "react";
import {PlayerResult} from "../../../model/PlayerResult";
import {canvasFilledPercentage} from "../../../utils/canvasFilledPercentage";
import {clearCanvas} from "../../../utils/clearCanvas";
import {Coords} from "../../../model/Coords";
import './PlayField.css';

export type PlayFieldProps = {
    player: string;
    onResult: (result: PlayerResult) => void;
}

type DrawingState = {
    started: boolean;
    drawing: boolean;
}

export const PlayField: FC<PlayFieldProps> = ({player, onResult}) => {
    const [currentResult, setCurrentResult] = useState<number>(0);
    const [drawingState, setDrawingState] = useState<DrawingState>({started: false, drawing: false});

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const lastCoords = useRef<Coords|null>(null);

    useEffect(() => {
        setCurrentResult(0);
        setDrawingState({started: false, drawing: false});
        if (canvasRef.current) {
            clearCanvas(canvasRef.current);
        }
    }, [player]);

    const finish = useCallback(() => {
        if (canvasRef.current) {
            const result = canvasFilledPercentage(canvasRef.current);

            onResult({player, result});
        }
    }, [onResult, player]);

    const start = useCallback<MouseEventHandler<HTMLCanvasElement>>((event) => {
        if (!drawingState.started) {
            window.setTimeout(finish, 5000);
        }

        setDrawingState({
            started: true,
            drawing: true,
        });
        lastCoords.current = {
            x: event.pageX - event.currentTarget.offsetLeft,
            y: event.pageY - event.currentTarget.offsetTop,
        }
    }, [drawingState.started, finish]);

    const draw = useCallback<MouseEventHandler<HTMLCanvasElement>>((event) => {
        const context = canvasRef.current ? canvasRef.current.getContext('2d') : null;

        if (context && canvasRef.current && drawingState.drawing) {
            context.lineWidth = 16;
            context.lineCap = 'round';

            const newCoords: Coords = {
                x: event.pageX - event.currentTarget.offsetLeft,
                y: event.pageY - event.currentTarget.offsetTop,
            }

            context.beginPath();
            context.moveTo(newCoords.x, newCoords.y);

            if (lastCoords.current !== null) {
                context.lineTo(lastCoords.current.x, lastCoords.current.y);
            }

            context.stroke();
            context.closePath();

            lastCoords.current = {
                x: event.pageX - event.currentTarget.offsetLeft,
                y: event.pageY - event.currentTarget.offsetTop,
            }

            setCurrentResult(canvasFilledPercentage(canvasRef.current));
        }
    }, [drawingState]);

    const stop = useCallback(() => {
        setDrawingState({
            ...drawingState,
            drawing: false,
        });
    }, [drawingState]);

    const canvasWidth = document.documentElement.clientWidth * 0.8;
    const canvasHeight = document.documentElement.clientHeight * 0.5;

    return <div className="PlayField">
        <p>Výsledek: <strong>{currentResult.toFixed(1)}</strong></p>
        <canvas
            width={canvasWidth}
            height={canvasHeight}
            ref={canvasRef}
            onMouseDown={start}
            onMouseMove={draw}
            onMouseUp={stop}
        />
        <div className="PlayField__progress-wrapper">
            {drawingState.started && <div className="PlayField__progress" />}
        </div>
    </div>
}
