import {ChangeEvent, FC, useCallback, useState} from "react";
import {PlayerNamesForm} from "./PlayerNamesForm/PlayerNamesForm";
import './SelectPlayers.css';

export type SelectPlayersProps = {
    storePlayers: (players: string[]) => void;
}

export const SelectPlayers: FC<SelectPlayersProps> = ({storePlayers}) => {
    const [playersCount, setPlayersCount] = useState<number|null>(null);

    const onChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setPlayersCount(parseInt(event.target.value, 10));
    }, [])

    const resetCount = useCallback(() => {
        setPlayersCount(null);
    }, [])

    return <div className="SelectPlayers">
        {!playersCount && <div>
            <h2>Kolik vás bude hrát?</h2>
            <select onChange={onChange} defaultValue="">
                <option value="" disabled={true}>Vyberte počet hráčů</option>
                {Array.from({length: 20}).map((_, index) => {
                    const value = index + 1;
                     return <option key={value} value={value}>{value}</option>
                })}
            </select>
        </div>}
        {playersCount && <div>
            <PlayerNamesForm playersCount={playersCount} storePlayers={storePlayers} resetCount={resetCount} />
        </div>}
    </div>
}
