import React, {FC, useCallback, useEffect, useState} from 'react';
import {clearLocalStorage, readLocalStorage, writeLocalStorage} from "./utils/localStorage";
import {SelectPlayers} from "./components/SelectPlayers/SelectPlayers";
import {PlayGame} from "./components/PlayGame/PlayGame";
import './DrawGame.css';

const PLAYERS_STORAGE_KEY = 'drawGame-players';

export const DrawGame: FC = () => {
    const [players, setPlayers] = useState<string[]|null>(null);

    useEffect(() => {
        setPlayers(readLocalStorage<string[]>(PLAYERS_STORAGE_KEY));
    }, []);

    const storePlayers = useCallback((newPlayers: string[]) => {
        setPlayers(newPlayers);
        writeLocalStorage<string[]>(PLAYERS_STORAGE_KEY, newPlayers);
    }, []);

    const resetPlayers = useCallback(() => {
        setPlayers(null);
        clearLocalStorage();
    }, []);

    return <div className="DrawGame">
        <h1>Draw Game</h1>
        {players === null && <SelectPlayers storePlayers={storePlayers} />}
        {players !== null && <PlayGame players={players} resetPlayers={resetPlayers} />}
    </div>
}
