export const readLocalStorage = <T>(key: string): T|null => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) as T : null;
    } catch (error) {
        writeLocalStorage(key, null);
        return null;
    }
}

export const writeLocalStorage = <T>(key: string, value: T|null) => {
    if (value === null) {
        window.localStorage.removeItem(key);
    } else {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
}

export const clearLocalStorage = (): void => {
    window.localStorage.clear();
}

